/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import intro_graph from "../imgs/intro_graph.png"
import weare_hire from "../imgs/weare_hire.svg"
import weare_permanence from "../imgs/weare_permanence.svg"
import weare_scaling from "../imgs/weare_scaling.svg"
import weare_team from "../imgs/weare_team.svg"
import weare_value from "../imgs/weare_value.svg"
import wedo_action from "../imgs/wedo_action.svg"
import wedo_architecture from "../imgs/wedo_architecture.svg"
import wedo_business from "../imgs/wedo_business.svg"
import wedo_facts from "../imgs/wedo_facts.svg"
import wedo_project from "../imgs/wedo_project.svg"
import wedo_software from "../imgs/wedo_software.svg"
import wedo_vision from "../imgs/wedo_vision.svg"
import wedoit_icon from "../imgs/wedoit_icon.svg"
import wedoit_graph from "../imgs/wedoit_graph.svg"

import teamMembers from "./teamMembers"
import TeamList from "./team-list"
import CaseStudiesHorizontalList from "./case-studies-horizontal-list"
import Awards from "./awards"

const Home = () => {
  return (
    <div>
      {/* intro */}
      <section id="intro" className="home-intro">
        <div className="container">
          <div className="row row--center">
            <div className="col col__6 col__l7 col__m12">
              <h2 className="home-intro__title">
                Have a Technology Challenge?
              </h2>
              <p className="home-intro__desc">
                We get fired up when presented with uncommon technology
                challenges…
              </p>
              <Link
                className="link__contact2 button button--large button--icon-end"
                to={`/contact-us`}
              >
                Let's Talk
                <span className="icon material-icons">arrow_right_alt</span>
              </Link>
            </div>

            <div className="col col__6 col__l5 col__m12">
              <img src={intro_graph} alt="" className="home-intro__image" />
            </div>
          </div>

        </div>
      </section>
      <section className="home-intro">
          <div className="container">
            <Awards />
          </div>
      </section>
      {/* intro */}
      {/* What We Do */}
      <section id="whatwedo" className="wedo">
        <div className="container">
          <div className="wedo__first row">
            <div className="col col__5 col__l4 col__m12">
              <div className="wedo__first-start">
                <h2 className="title">What We Do</h2>
                <h3 className="subtitle">First, We Focus on Understanding</h3>
                <p className="description">
                  We believe that a comprehensive understanding of business
                  priorities and the current state of the business yield the
                  best technology-driven solutions.
                </p>
              </div>
            </div>
            <div className="col col__7 col__l8 col__m12">
              <div className="wedo__first-end">
                <article className="wedo__card wedo__card--business">
                  <img
                    src={wedo_business}
                    alt="What We Do"
                    className="wedo__card-icon"
                  />
                  <h4 className="wedo__card-title">The Business Drivers</h4>
                  <ul className="wedo__card-list">
                    <li>What is driving the technology need?</li>
                    <li>What are the external pressures?</li>
                    <li>What are the internal constraints?</li>
                    <li>What are the business priorities?</li>
                  </ul>
                </article>
                <article className="wedo__card wedo__card--facts">
                  <img src={wedo_facts} alt="" className="wedo__card-icon" />
                  <h4 className="wedo__card-title">The Facts</h4>
                  <ul className="wedo__card-list">
                    <li>What resources are available?</li>
                    <li>What is working?</li>
                    <li>What are current capabilities?</li>
                    <li>How are things done?</li>
                    <li>What technology is in place already?</li>
                    <li>What are the constraints?</li>
                    <li>What are the risks?</li>
                  </ul>
                </article>
                <article className="wedo__card wedo__card--vision">
                  <img src={wedo_vision} alt="" className="wedo__card-icon" />
                  <h4 className="wedo__card-title">The Vision</h4>
                  <ul className="wedo__card-list">
                    <li>What does the new solution look like?</li>
                    <li>What problem is being solved?</li>
                    <li>What is the business case?</li>
                    <li>What do users/customers want?</li>
                  </ul>
                </article>
                <article className="wedo__card wedo__card--action">
                  <img src={wedo_action} alt="" className="wedo__card-icon" />
                  <h4 className="wedo__card-title">The Action Plan</h4>
                  <ul className="wedo__card-list">
                    <li>How does one get there from here?</li>
                    <li>How will the gaps be closed?</li>
                    <li>How much will it cost?</li>
                    <li>How long will it take?</li>
                    <li>How do we manage it all?</li>
                  </ul>
                </article>
              </div>
            </div>
          </div>
          <div className="wedo__next row">
            <div className="col col__12">
              <div className="wedo__next-start">
                <h3 className="subtitle">Next, We Deploy The Right Know-How</h3>
                <p className="description">
                  Our ever evolving expertise focuses on leading technologies.
                </p>
              </div>
              <div className="wedo__next-end">
                <article className="wedo__card wedo__card--horizontal wedo__card--project">
                  <img src={wedo_project} alt="" className="wedo__card-icon" />
                  <div>
                    <h4 className="wedo__card-title">
                      Project and Product Management
                    </h4>
                    <p className="wedo__card-description">
                      We manage the process of bringing ideas and invocation to
                      life, maximizing the use of available resources while
                      operating within our clients’ constraints and resources.
                    </p>
                  </div>
                </article>
                <article className="wedo__card wedo__card--horizontal wedo__card--architecture">
                  <img
                    src={wedo_architecture}
                    alt=""
                    className="wedo__card-icon"
                  />
                  <div>
                    <h4 className="wedo__card-title">
                      Architecture and Infrastructure
                    </h4>
                    <p className="wedo__card-description">
                      We define long-term, stable, scalable, maintainable, and
                      responsive foundations to enable our clients’ technology
                      transformations.
                    </p>
                  </div>
                </article>
                <article className="wedo__card wedo__card--horizontal wedo__card--software">
                  <img src={wedo_software} alt="" className="wedo__card-icon" />
                  <div>
                    <h4 className="wedo__card-title">Software Development</h4>
                    <p className="wedo__card-description">
                      We bring innovation and ideas to life using the best
                      technologies for every unique situation supported by the
                      latest practices, frameworks and methodologies.
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* What We Do */}
      {/* Who We Are */}
      <section id="whoweare" className="weare">
        <div className="container">
          <div className="row row--center">
            <div className="col col__10 col__m12">
              <h2 className="title title--center">Who We Are</h2>
              <article className="weare__item">
                <img src={weare_hire} alt="" className="weare__item-icon" />
                <div>
                  <h3 className="weare__item-title">We Hire The Best</h3>
                  <p className="weare__item-description">
                    We strongly believe that our people and our culture are our
                    competitive differentiators. Passionate technologists and
                    passion to build an extraordinary team with an extraordinary
                    reputation.
                  </p>
                </div>
              </article>
              <article className="weare__item">
                <img src={weare_team} alt="" className="weare__item-icon" />
                <div>
                  <h3 className="weare__item-title">Team Integration</h3>
                  <p className="weare__item-description">
                    We aspire to seamlessly integrate into our clients’ teams
                    regardless of whether we are in the same room or across the
                    world. We believe that integration maximizes productivity by
                    creating unquestionable energy.
                  </p>
                </div>
              </article>
              <article className="weare__item">
                <img
                  src={weare_permanence}
                  alt=""
                  className="weare__item-icon"
                />
                <div>
                  <h3 className="weare__item-title">
                    Permanence and Stability
                  </h3>
                  <p className="weare__item-description">
                    We provide committed teams of top technologists to our
                    clients’ in a highly competitive world. Our culture fosters
                    long-term commitment from our people. Our goal is to
                    minimize disruptions and onboarding inefficiencies.
                  </p>
                </div>
              </article>
              <article className="weare__item">
                <img src={weare_value} alt="" className="weare__item-icon" />
                <div>
                  <h3 className="weare__item-title">Uncommon Value</h3>
                  <p className="weare__item-description">
                    We always look at things from a number of angles
                    (architecture, infrastructure, tools, frameworks,
                    methodologies, concepts, techniques, management) to reach
                    highly effective solutions that significantly increase the
                    expected value delivered to our clients.
                  </p>
                </div>
              </article>
              <article className="weare__item">
                <img src={weare_scaling} alt="" className="weare__item-icon" />
                <div>
                  <h3 className="weare__item-title">Scaling Flexibility</h3>
                  <p className="weare__item-description">
                    We deploy best team formation practices that optimize
                    onboarding and offboarding team resources as projects grow,
                    timelines shift, or budgets change.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      {/* Who We Are */}
      {/* The Team */}
      <section id="theteam" className="team">
        <div className="container">
          <div className="row">
            <div className="col col__12">
              <h2 className="title title--center">Meet The Team</h2>
              <h3 className="subtitle subtitle--center">
                The Talent Inside MangoChango
              </h3>
            </div>
          </div>
          <TeamList limit="4" teamList={teamMembers}></TeamList>
          <div className="row">
            <div className="col col__12">
              <div className="button__group button__group--center">
                <Link className="button button--secondary" to={`/team`}>
                  More People
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* The Team */}
      {/* How Do We Do It */}
      <section id="howwedoit" className="wedoit">
        <div className="container">
          <div className="row">
            <div className="col col__12">
              <h2 className="title title--center">How Do We Do It</h2>
              <div className="wedoit__container">
                <div className="wedoit__graph">
                  <img
                    className="wedoit__graph-icon"
                    alt=""
                    src={wedoit_icon}
                  />
                  <img
                    className="wedoit__graph-chart"
                    alt=""
                    src={wedoit_graph}
                  />
                </div>
                <ul className="wedoit__list wedoit__list--first">
                  <li className="wedoit__item wedoit__item--analysis">
                    <h3 className="wedoit__item-title">Analysis</h3>
                    <p className="wedoit__item-desc">
                      Understand goals, requirements, process flows, and data
                      needs.
                    </p>
                    <p className="wedoit__item-meta">Visio, Archi, Erwin</p>
                  </li>
                  <li className="wedoit__item wedoit__item--design">
                    <h3 className="wedoit__item-title">Design</h3>
                    <p className="wedoit__item-desc">
                      Define look, function, user experience, and ergonomics.
                    </p>
                    <p className="wedoit__item-meta">
                      Adobe XD, InVision, Sketch, Axure, Framer X
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--devs">
                    <h3 className="wedoit__item-title">Devs</h3>
                    <p className="wedoit__item-desc">
                      Build front and back end components efficiently and for
                      ease of support.
                    </p>
                    <p className="wedoit__item-meta">
                      Ruby on Rails, Scala, Python, Django, PHP, Laravel, MEAN,
                      LAMP, React, Hadoop, Java, Go, Clojure, C#, Angular, .Net,
                      TensorFlow, SQL/NoSQL databases, REST, Javascript, jQuery,
                      HTML/CSS
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--testing">
                    <h3 className="wedoit__item-title">Testing</h3>
                    <p className="wedoit__item-desc">
                      Ensure it works, is correct, and secure, including
                      automation, at the unit, integration, and user acceptance
                      levels.
                    </p>
                    <p className="wedoit__item-meta">
                      Selenium, UFT/QTP, Katalon, Squish, SonarQube
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--cicd">
                    <h3 className="wedoit__item-title">CI/CD</h3>
                    <p className="wedoit__item-desc">
                      Define and manage continuous integration and continuous
                      deployment capabilities.
                    </p>
                    <p className="wedoit__item-meta">
                      GitLab, Jenkins, Bitbucket, TeamCity, Codeship
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--devops">
                    <h3 className="wedoit__item-title">DevOps</h3>
                    <p className="wedoit__item-desc">
                      Define a clear software delivery pipeline, infrastructure
                      automation, increase cross-functional collaboration.
                    </p>
                    <p className="wedoit__item-meta">
                      CI/CD, IaaS, Containerization, Monitoring, Cloud
                    </p>
                  </li>
                </ul>
                <ul className="wedoit__list wedoit__list--second">
                  <li className="wedoit__item wedoit__item--product">
                    <h3 className="wedoit__item-title">Product</h3>
                    <p className="wedoit__item-desc">
                      Define scope and timelines for product functionality
                      deployment.
                    </p>
                    <p className="wedoit__item-meta">
                      ProductPlan, Pendo, Amplitude, Confluence, FullStory
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--management">
                    <h3 className="wedoit__item-title">Management</h3>
                    <p className="wedoit__item-desc">
                      Plan, execute, track, pivot, manage and optimize
                      resources.
                    </p>
                    <p className="wedoit__item-meta">
                      Scrum, Lean, Kanban, Agile, Waterfall, Slack, Asana,
                      Trello, CommandHound, Jira
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--architecture">
                    <h3 className="wedoit__item-title">Architecture</h3>
                    <p className="wedoit__item-desc">
                      Define the functional and quality attributes and the
                      technologies/tools/frameworks to use.
                    </p>
                    <p className="wedoit__item-meta">
                      Backend, Front-end, Infrastructure, Cloud
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--infrastructure">
                    <h3 className="wedoit__item-title">Infrastructure</h3>
                    <p className="wedoit__item-desc">
                      Define and manage where technology solutions live, operate
                      ad how things get there.
                    </p>
                    <p className="wedoit__item-meta">
                      AWS, Puppet, Azure, Google Cloud, Ansible
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--analytics">
                    <h3 className="wedoit__item-title">Analytics</h3>
                    <p className="wedoit__item-desc">
                      Measure and understand how things are being used, what is
                      working and where it can be improved.
                    </p>
                    <p className="wedoit__item-meta">
                      Google Analytics, Matomo, Heap, Woopra, Splunk
                    </p>
                  </li>
                  <li className="wedoit__item wedoit__item--people">
                    <h3 className="wedoit__item-title">People</h3>
                    <p className="wedoit__item-desc">
                      Form, manage, develop and retain your team.{" "}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Case Studies*/}
      <StaticQuery
        query={graphql`
          query homeCaseSTudies {
            relatedCaseStudies: allWordpressWpCaseStudies(
              sort: { fields: date, order: DESC }
              limit: 3
            ) {
              edges {
                node {
                  id
                  slug
                  date
                  title
                  tags {
                    id
                  }
                  acf {
                    the_problem
                    the_solution
                    featured_image {
                      sizes {
                        thumbnail
                        medium
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const caseStudies = data.relatedCaseStudies.edges
          if (caseStudies.length === 0) {
            return null
          }
          return (
            <CaseStudiesHorizontalList
              title="Case Studies"
              caseStudies={caseStudies}
            ></CaseStudiesHorizontalList>
          )
        }}
      />
    </div>
  )
}
export default Home
